import React from 'react'
import styles from "../stylesheets/about.module.css"
import logo from "../images/vincent-jonzon-logo.svg"

export default function PageAbout() {
  return (
    <div className={styles["container"]}>
        <div className={styles['text-left']}><h2>V</h2></div>
        <div className={styles['text-main']}>
        <strong>Om Vincent Jonzon.</strong>
            <p>incent Jonzon är pseudonym för en svensk konstnär/designer med en utpräglad egen stil och teknik. 
               Han gör främst original-akvareller som bearbetas digital i numrerade serigrafier.</p>
            <p>Denna del är under uppbyggnad, men redan nu kan Vincent erbjuda 
            enstaka produkter.</p>
            <p>Våra kunder är både privatpersoner och företag som vill ha vacker konst och design. Vincent kan också specialdesigna både
            enskilda konstverk och inredning/möbler, men också hela miljöer som vardagsrum, konferensrum, lobbyer och hotellrum.</p>
            <img src={logo} className={styles['logo']} alt="Vincent Jonzon" />
        </div>
    </div>
  )
}
