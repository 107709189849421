import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Changed Router to BrowserRouter
import PageHome from './pages/PageHome';
import PageAbout from './pages/PageAbout';
import PageContact from './pages/PageContact';
import Header from './components/Header';
import axios from 'axios';


function App() {

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [fetchProduct, setFetchProduct] = useState(1)
  useEffect(() => {
    const fetchData = async () => {
      console.log('change')
      try {
        const response = await fetch('/products.json');
        const products = await response.json();
        // Assuming you want to select a product with ID 1
        const product = products.find(product => product.id === fetchProduct);
        setProduct(product);
      
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false)
      }
    };

    fetchData();
  }, [fetchProduct]);

  return (
    <div className="App">
    
        <Router>
        <Header />
        <div className="main">
          <Routes>
            {!loading && <Route exact path="/" element={<PageHome product={product} setFetchProduct={setFetchProduct}/>} />} 
            <Route exact path="/om-vincent" element={<PageAbout />} /> 
            <Route exact path="/kontakt" element={<PageContact />} /> 
          </Routes>
          </div>   
        </Router>  
    </div>
  );
}

export default App;

