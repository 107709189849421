import React from 'react'
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <div className="header">
        <div className="navigation">
             <Link to="/" className="link-page">Hem</Link>
             <Link to="/om-vincent" className="link-page">Om Vincent</Link>
             <Link to="/" className="link-page">Tavlor</Link>
             <Link to="/" className="link-page">Utställningar</Link>
             <Link to="/kontakt" className="link-page">Kontakt</Link>
        </div>
    </div>
  )
}
