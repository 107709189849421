import React, { useEffect, useState } from 'react'
import { MdArrowCircleRight } from "react-icons/md";

export default function PageHome({ product }) {

  const [selectedImage, setSelectionImage] = useState(0);


  return (
    <div className="page-contact">
    <h3>Kontakt</h3>
    <p>Om du är intresserad av mina målningar eller eventuella utställningar, tveka inte att kontakta mig. 
       Jag är öppen för samtal om konstverk och möjliga utställningsmöjligheter. 
       Skicka gärna ett meddelande så återkommer jag till dig så snart som möjligt.</p>
       <div className="email"><b>E-Post:</b> <a href="mailto:vincentjonzon@gmail.com">vincentjonzon@gmail.com</a></div>
    </div>
  )
}
