import React, { useEffect, useState } from 'react'
import { MdArrowCircleRight } from "react-icons/md";

export default function PageHome({ product, setFetchProduct }) {

  const [selectedImage, setSelectionImage] = useState(0);

  return (
    <div className="column-grid">
    
    <div className="column">
    <h2 className="title-home">Möbler och inredning med<br/>
    konstnärlig och unik design</h2>

    <div className="product-item">
    
    <div className="images">
    {Array.isArray(product.images) && product['images'].map((item, index) => (
        <div onClick={()=> setSelectionImage(index)} className="image">
          <img src={product.images[index]} alt="img-product" className={selectedImage === index ? 'selected' : 'default'}/> 
        </div>
    ))}
    </div>

    <div className="item-info">
      <div className="title">
        <strong>{product.name}</strong>
        <span className="color">{product.color}</span>
      </div>
      <div className="description">{product.description}</div>
      <div className="dimensions">
          
          <div className="i-size">
          <label>Bildmått</label> 
          <div className="size">
          <b>Bredd:</b> {product.image_width} x <b>Höjd:</b> {product.image_height} 
          </div>
          </div>

          <div className="i-size">
          <label>Arkmått</label> 
          <div className="size">
          <b>Bredd:</b> {product.canvas_width} x <b>Höjd:</b> {product.canvas_height} 
          </div>
          </div>


      </div>
      <div className="series"><label>Serie:</label> {product.series} ex.</div>

      <div className="options">
    <label>Färger:</label>
    {product['options'] && product['options'].map((color, index) => (
       <div 
       key={index} 
       onClick={()=> setFetchProduct(index + 1)}
       className={`option-style ${product.color_code === color ? "selected" : "default"}`}
       style={{ backgroundColor: color }}></div>
    ))}
    </div>

      <div className="price">{product.price}</div>    
    </div>

    

    </div>
    
    </div>

    <div className="image-container">
    <div className="button-explore">Utforska <MdArrowCircleRight size={20} /></div>

    {product.images && product.images.hasOwnProperty(selectedImage) ? (
    <img src={product.images[selectedImage]}  alt="img-product"/> 
    ) : (
      <></>
    )}
    
     
 
    
    </div>

    </div>
  )
}
